<template>
    <div>
        <b-row>
            <b-col cols="12" lg="10">
                <b-card no-body>
                    <b-card-header>
                        <div>
                            <div class="d-flex mb-2 align-items-center">
                                <h3 class="text-primary pr-2 mb-0"><strong>GetUp</strong></h3>
                                <vuexy-logo width="32" height="32" />
                            </div>
                            <p style="width: 300px" class="text-right"></p>
                        </div>
                        <div>
                            <b-form-group class="align-items-center" label="فاتورة رقم" label-cols="4" label-size="sm" label-for="invoceNumber">
                                <b-form-input
                                    style="width:120px"
                                    class="ml-auto"
                                    id="invoceNumber"
                                    type="text"
                                    readonly size="sm"
                                    v-model="invoiceDto.invoiceNumber"
                                >
                                </b-form-input>
                            </b-form-group>
                            <b-form-group class="align-items-center" label="تاريخ الفاتورة" label-cols="4" label-size="sm" label-for="invoceDate">
                                <b-form-input
                                    style="width:120px"
                                    class="ml-auto"
                                    id="invoceDate"
                                    type="text"
                                    readonly size="sm"
                                    :value="new Date(invoiceDto.invoiceDate).toISOString().substr(0, 10)"
                                >
                                </b-form-input>
                            </b-form-group>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <vue-good-table
                            :columns="columns[+type - 1]"
                            :rows="type == 1 ? invoiceDto.teacherDtos : (type == 2 ? invoiceDto.respondDtos : invoiceDto.codeDtos)"
                            :rtl="false"
                            :small="true"
                            styleClass="vgt-table condensed" 
                            ref="accounts-table"
                        >
                            <template
                                slot="table-row"
                                slot-scope="props"
                            >
                                <span v-if="props.column.field === 'respondingDate' || props.column.field === 'dateActivated'">
                                    {{ new Date(props.formattedRow[props.column.field]).toISOString().substr(0, 10) }}
                                </span>
                                <span
                                    v-else-if="props.column.field === 'numberOfSubscriptions' || props.column.field === 'unitName' || props.column.field === 'lessonNumber' || props.column.field === 'rate' || props.column.field === 'discountRate' || props.column.field === 'posRate'">
                                    <b-form-input
                                        v-if="type == 1"
                                        :type="(props.column.field === 'numberOfSubscriptions') ? 'number' : 'text'"
                                        class="mx-auto bg-white text-center"
                                        style="width: 140px"
                                        size="sm"
                                        readonly
                                        :max="props.column.field === 'numberOfSubscriptions' ? props.formattedRow[props.column.field].baseNumberOfSubscriptions : Number.MAX_SAFE_INTEGER"
                                        v-model="invoiceDto.teacherDtos[props.index][props.column.field]"
                                    >
                                    </b-form-input>
                                    <b-form-input
                                        v-else-if="type == 2"
                                        type="text"
                                        style="width: 140px"
                                        readonly
                                        size="sm"
                                        class="mx-auto bg-white text-center"
                                        v-model="invoiceDto.respondDtos[props.index][props.column.field]"
                                    >
                                    </b-form-input>
                                    <b-form-input
                                        v-else-if="type == 3"
                                        type="text"
                                        style="width: 140px"
                                        readonly
                                        size="sm"
                                        class="mx-auto bg-white text-center"
                                        v-model="invoiceDto.codeDtos[props.index][props.column.field]"
                                    >
                                    </b-form-input>
                                </span>
                                <!-- Column: Common -->
                                <span v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                            </template>
                        </vue-good-table>
                        <b-row class="mt-2">
                            <b-col cols="12" lg="8">
                                <b-form-group label="ملاحظات" label-for="note">
                                    <b-form-textarea readonly v-model="invoiceDto.notes" id="note" rows="6"></b-form-textarea>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="4">
                                <b-row>
                                    <b-col cols="6" lg="12">
                                        <ul class="list-unstyled p-0 m-0">
                                            <li class="d-flex pb-1 pt-2 justify-content-between align-items-center">
                                                <div>{{type == 1 ? 'عدد الإشتراكات' : (type == 2 ? 'عدد الإجابات' : 'عدد الأكواد')}}</div>
                                                <div>{{invoiceDto.codeCounts}}</div>
                                            </li>
                                            <li class="d-flex pt-1 justify-content-between align-items-center">
                                                <div>الكلفة</div>
                                                <div>{{type == 1 ? invoiceDto.valueWithOutDiscount : invoiceDto.totalValue}} ل.س</div>
                                            </li>
                                        </ul>
                                    </b-col>
                                    <b-col cols="12">
                                        <hr class="d-none d-lg-block">
                                    </b-col>
                                    <b-col cols="6" lg="12">
                                        <ul class="list-unstyled p-0">
                                            <li class="d-flex justify-content-between align-items-center">
                                                <div v-if="type == 1 || type == 2">المستحق له</div>
                                                <div v-else>المستحق لنا</div>
                                                <div>{{invoiceDto.totalValue}} ل.س</div>
                                            </li>
                                        </ul>
                                        <div>
                                            <b-input-group class="d-flex">
                                                <b-input-group-prepend is-text>
                                                    المدفوع
                                                </b-input-group-prepend>
                                                <b-form-input disabled type="number" v-model="invoiceDto.paidValue"></b-form-input>
                                                <b-input-group-append is-text>
                                                    ل.س
                                                </b-input-group-append>
                                            </b-input-group>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                cols="12"
                lg="2"
                class="invoice-actions"
            >
                <b-card>
                    <b-button
                        variant="primary"
                        block
                        @click="print()"
                        >طباعة</b-button>
                    <b-button variant="outline-primary" block to="../">عودة</b-button>
                    <b-button variant="outline-danger" block @click="RemoveInvoice()">حذف</b-button>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { VueGoodTable } from 'vue-good-table'
import {
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BRow,
    BFormInput,
    BFormGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormTextarea,
    BInputGroup
} from "bootstrap-vue";
import { mapGetters, mapActions } from 'vuex';
import VuexyLogo from "@core/layouts/components/Logo.vue";
export default {
    props: {
        type: String,
        userId: String,
        invoiceId: String
    },
    components: {
        BButton,
        BCard,
        BCardHeader,
        BCardBody,
        BCol,
        BRow,
        BFormInput,
        BFormGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        VueGoodTable,
        BFormTextarea,
        BInputGroup,
        VuexyLogo
    },
    data: () => ({
        columns: [
            [
                {
                    label: 'المادة',
                    field: 'subjectName',
                },
                {
                    label: 'النسبة',
                    field: 'rate',
                },
                {
                    label: 'عدد الإشتراكات',
                    field: 'numberOfSubscriptions',
                },
                {
                    label: 'الوحدة',
                    field: 'unitName',
                },
                {
                    label: 'الكلفة',
                    field: 'price',
                }
            ],
            [
                {
                    label: 'الجواب',
                    field: 'answer',
                },
                {
                    label: 'التاريخ',
                    field: 'respondingDate',
                },
                {
                    label: 'رقم الدرس',
                    field: 'lessonNumber',
                },
                {
                    label: 'الوحدة',
                    field: 'unitName',
                },
                {
                    label: 'الكلفة',
                    field: 'price',
                }
            ],
            [
                {
                    label: 'الكود',
                    field: 'code',
                },
                {
                    label: 'التاريخ',
                    field: 'dateActivated',
                },
                {
                    label: 'النسبة',
                    field: 'posRate',
                },
                {
                    label: 'الحسم %',
                    field: 'discountRate',
                },
                {
                    label: 'الكلفة',
                    field: 'price',
                }
            ]
        ]
    }),
    created() {
        this.getInvoiceByid({id: this.userId, type: this.type, invoiceId: this.invoiceId})
    },
    computed: {
        ...mapGetters(['invoiceDto'])
    },
    methods: {
        ...mapActions(['getInvoiceByid',"removeInvoice"]),
        print(){
            this.$router.push('./print')
        },
        RemoveInvoice(){
            this.removeInvoice(+this.invoiceId)
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/components/invoicePrint.scss";
.removed {
    background: #ea545560!important;
}
.input-group-append {
    .input-group-text {
        border-radius: 0 0.357rem 0.357rem 0!important;
    }
}
.input-group-prepend {
    .input-group-text {
        border-radius: 0.357rem 0 0 0.357rem!important;
    }
}
</style>